.loginRegister {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .container {
        width: 350px;

        .title {
            text-align: center;
            font-size: 28px;
            font-weight: 600;
            font-family: $rubik;
            color: $textSecondary;
        }
        .desc {
            text-align: center;
            color: $textTertiary;
            font-size: 15px;
            margin-top: 10px;
            margin-bottom: 30px;
            font-weight: 400;
        }
        .step {
            text-align: center;
            color: $textTertiary;
            font-size: 14px;
            margin-bottom: 6px;
            font-weight: 400;
        }

        .noAccount {
            color: $textTertiary;
            text-align: center;
            margin: 20px 0px;
            font-size: 14px;

            span {
                color: $main;
                font-weight: 500;
            }
        }

        .loginWithSocial {
            width: 300px;
            margin: 30px auto;
            padding-left: 5px;
        }

        .form {
            .inputSingle {
                position: relative;
                margin-bottom: 30px;

                .inputName {
                    margin-bottom: 12px;
                    span {
                        display: inline-block;
                        color: $text;
                        font-size: 15px;
                        font-family: $rubik;
                        font-weight: 500;
                    }
                    .inputForgotPass {
                        position: absolute;
                        right: 0;
                        top: 2px;
                        color: $main;
                        font-size: 13px;
                        font-weight: 500;
                    }
                }
                .errorMessage {
                    text-align: right;
                    font-size: 12px;
                    margin-top: 4px;
                    color: $red;
                    font-weight: 500;
                    position: absolute;
                    right: 0;
                }
            }
            input {
                width: 100%;
            }
            .terms {
                font-size: 13px;
                color: $text;
                font-family: $rubik;

                a {
                    color: $main !important;
                    font-weight: 500;
                }
            }
            .inputButton {
                margin-top: 20px;
            }
            .passwordIndicator {
                display: flex;
                flex-direction: column;
                gap: 8px;
                margin: -20px 0px -10px 0px;

                .passwordIndicatorSingle {
                    display: flex;
                    font-size: 12px;
                    font-weight: 400;
                    color: $text;
                    font-weight: 500;
                    gap: 10px;
                    align-items: center;

                    img {
                        width: 8px;
                    }
                    span {
                        opacity: 0.6;
                    }
                }
            }
        }
    }

    .logoIcon {
        position: absolute;
        left: 20px;
        top: 20px;

        img {
            width: 50px;
        }
    }
}
