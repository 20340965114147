.colorPicker {
    .content {
        .colorPick {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;

            .colorTitle {
                color: $textTertiary;
                font-weight: 500;
                text-transform: uppercase;
                margin: 20px 0px;
            }
        }
    }
}