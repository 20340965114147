.changeUsername {
    .content {
        margin-top: 20px;
        position: relative;
        
        .errorMessage {
            text-align: right;
            font-size: 12px;
            margin-top: 4px;
            color: $red;
            font-weight: 500;
            position: absolute;
            right: 0;
        }
    }
}