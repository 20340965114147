.profileSelect {
    .content {
        text-align: center;
        .previewImage {
            display: inline-block;
            border-radius: 50%;
            width: 150px;
            height: 150px;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            margin-top: 20px;
        }
    }
}