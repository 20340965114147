.darkModeSwitch{
    display: flex;
    gap: 6px;
    background-color: $background;
    padding: 2px 4px;
    border-radius: 20px;

    .darkModeSwitchBox {
        padding: 4px 4px 0px 4px;
        border-radius: 50%;
        cursor: pointer;
    }

    .darkModeSwitchBox.activeBox {
        background-color: $main;
        svg {
            fill: $white;
        }
    }
}