.leftMenu {
    position: fixed;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $white;
    margin: 16px 32px;
    padding: 8px;
    border-radius: 16px;
    box-sizing: border-box;
    height: calc(100vh - 65px - 32px);
    bottom: 0;
    left: 0;

    .menuIconSingle {
        padding: 8px 8px 4px 8px;
        border-radius: 12px;

        img {
            width: 24px;
        }
    }
    .activeIcon {
        background-color: $background !important;
    }
    .setIcons {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}