.dashboardStats {
    .statsContent {
        margin-top: 24px;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 20px;

        .statsContentSingle {
            display: flex;
            gap: 10px;

            .iconBox {
                display: inline-flex;
                background-color: $bodyBackground;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                justify-content: center;
                align-items: center;
            }

            .num {
                display: flex;
                flex-direction: column;
                gap: 2px;

                .number {
                    color: $text;
                    font-family: $rubik;
                    font-weight: 600;
                    font-size: 28px;
                }
                .desc {
                    color: $textTertiary;
                    font-size: 14px;
                }
            }
        }
    }
}