@media only screen and (max-width: 992px) {
    .leftMenu {
        display: none;
    }
    .mobileNavIcon {
        display: inline-block !important;
    }
    .dashboard .right {
        display: none !important;
    }
    .dashboard .left {
        padding-right: 0px;
    }
    .dashboardContainer {
        padding-left: 32px;
    }
    .header .previewLink {
        display: inline-block;
    }
    .header .previewButton {
        display: none;
    }
    .mobilePreview {
        display: inline-block !important;
    }
}