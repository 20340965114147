.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    height: 65px;
    padding: 0px 32px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    box-sizing: border-box;
    z-index: 99;

    .logo {
        .dark-logo {
            display: none;
        }
        img {
            height: 27px;
        }
    }

    .mobileNavIcon {
        display: none;
    }

    .previewLink {
        display: none;
        color: $tertiary;
        font-weight: 500;
    }

    .leftSide{
        display: flex;
        gap: 16px;
        align-items: center;
    }

    .mobileNav {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        padding: 16px;
        box-sizing: border-box;
        background-color: $white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0px 0px 16px 0px #0000001a;

        .closeMenu {
            display: inline-block;
            margin-bottom: 16px;
            text-align: center;
        }

        .menuIconSingle {
            display: inline-block;
            padding: 8px 8px 4px 8px;
            border-radius: 12px;
    
            img {
                width: 28px;
            }
        }
        .activeIcon {
            background-color: $background !important;
        }
        .setIcons {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }
}