.linkManager {
    .links {
        margin: 30px 0px;

        .linkSingle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $background;
            color: $text;
            border-radius: 12px;
            padding: 24px 24px 24px 16px;
            margin-bottom: 16px;
            -webkit-user-select: none;
            -ms-user-select: none; 
            user-select: none; 
            flex-grow: 0;
            max-width: 100%;
            box-sizing: border-box;

            .linkTitle {
                max-width: 100%;
                font-size: 18px;
                font-weight: 500;
                overflow-wrap: break-word;
                overflow: hidden;
                word-break: break-all;
            }
            .linkURL {
                font-size: 14px;
                font-weight: 500;
                opacity: 0.6;
                margin-top: 6px;
                overflow-wrap: break-word;
                overflow: hidden;
                word-break: break-all;
            }
            .linkDrag {
                cursor: grab;
                svg {
                    fill: $textTertiary;
                }
            }

            .linkContent {

            }

            .linkLeft {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 12px;
            }
            .linkRight {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;

                .linkEdit svg {
                    fill: $textTertiary;
                }
            }
        }
        .linkEditSection {
            border: 2px solid $background;
            border-radius: 12px;
            padding: 32px;
            margin-bottom: 16px;

            .form {
                display: flex;
                flex-direction: column;
                gap: 16px;

                .formSingle {
                    position: relative;

                    .formTitle {
                        font-size: 16px;
                        font-family: $rubik;
                        font-weight: 500;
                        color: $text;
                        margin-bottom: 12px;
                    }
                    input {
                        width: 100%;
                    }
                    .errorMessage {
                        text-align: right;
                        font-size: 12px;
                        margin-top: 4px;
                        color: $red;
                        font-weight: 500;
                        position: absolute;
                        right: 0;
                    }
                }
            }
            .linkEditSaveButton {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 24px;

                .close {
                    cursor: pointer;
                    display: inline-block;
                    color: $main;
                    font-weight: 500;
                }
            }
        }
        .addLinkButton {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
            background-color: $background;
            cursor: pointer;
            color: $text;
            border-radius: 12px;
            padding: 24px;
            margin-bottom: 16px;
            font-weight: 500;
            font-size: 17px;
            font-family: $rubik;
            -webkit-user-select: none;
            -ms-user-select: none; 
            user-select: none; 

            svg {
                fill: $text;
            }
        }
    }
}