body.dark {
    background-color: $bodyBackgroundDark !important;

    .darkModeSwitch {
        background-color: $backgroundSecondaryDark !important;
    }
    .block {
        background-color: $backgroundDark;
        .title {
            color: $white !important;
        }
        .statsContentSingle .num {
            .number, .desc {
                color: $white !important;
            }
            .desc {
                opacity: 0.6;
            }
        }
    }
    .notFound {
        color: $white !important;
    }
    .header {
        background-color: $backgroundDark;
        .default-logo {
            display: none;
        }
        .dark-logo {
            display: inline-block;
        }
        .activeIcon {
            background-color: $backgroundSecondaryDark !important;
        }
        svg {
            fill: $white;
        }
    }
    .mobileNav {
        background-color: $backgroundDark;
    }
    .loginRegister .container .title {
        color: $white !important;
    }
    .loginRegister .container .form .inputSingle .inputName span {
        color: $white !important;
    }
    .loginRegister .container .form .inputSingle .inputName .inputForgotPass {
        color: $white !important;
    }
    .loginRegister .container .noAccount span {
        color: $white !important;
    }
    .loginRegister .container .form .passwordIndicator .passwordIndicatorSingle {
        color: $white !important;
    }
    .linkStats .content .linksContent .linksContentSingle {
        background-color: $backgroundSecondaryDark !important;
        color: $white !important;
    }
    .block .formSingle .formTitle {
        color: $white !important;
    }
    .block .inputDesc {
        color: $white !important;
    }
    .linkManager .links .linkEditSection .linkEditSaveButton .close {
        color: $white !important;
    }
    .loadingScreen {
        background-color: $backgroundDark !important;
    }
    .profileLink {
        background-color: $backgroundDark;
        color: $white !important;
        svg {
            fill: $white;
        }
    }
    .button {
        svg {
            fill: $white !important;
        }
    }
    .button.buttonSecondary {
        background-color: $backgroundSecondaryDark !important;
        color: $white !important;
    }
    .linkSingle {
        background-color: $backgroundSecondaryDark !important;
        color: $white !important;
    }
    .linkManager .links .addLinkButton {
        background-color: $backgroundSecondaryDark !important;
        color: $white !important;
        svg {
            fill: $white;
        }
    }
    .sketch-picker {
        background-color: $backgroundSecondaryDark !important;
    }
    input, textarea, select {
        background-color: $backgroundSecondaryDark !important;
        color: $white !important;
        border: 1px $backgroundDark solid;
    }
    .leftMenu {
        background-color: $backgroundDark !important;
        .activeIcon {
            background-color: $backgroundSecondaryDark !important;
        }
        svg {
            fill: $white;
        }
    }
    .changePassword .content .formSingle .formTitle {
        color: $white !important;
    }
    .changePassword .content .passwordIndicator .passwordIndicatorSingle {
        color: $white !important;
    }
    .verifyEmailMessage {
        background-color: $backgroundDark;
    }
    .chrome-picker  {
        background: $backgroundSecondaryDark !important;

        svg {
            fill: white !important;
        }
        svg:hover {
            background: $backgroundDark !important;;
        }
        input {
            box-shadow: rgb(96 96 96) 0px 0px 0px 1px inset !important;
        }
    }

    .react-datepicker {
        background-color: $backgroundDark !important;
        color: white !important;
    }
    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
        color: white !important;
    }
    .react-datepicker__header {
        background-color: $backgroundSecondaryDark !important;
    }
    .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
        color: white !important;
    }
    .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
        background-color: $backgroundSecondaryDark !important;
    }
    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
        background-color: $backgroundSecondaryDark !important;
    }
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
        border-top-color: $backgroundDark !important;
    }
}