.changePassword {
    .content {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .formSingle {
            position: relative;
            
            .formTitle {
                margin: 10px 0px;
                font-size: 14px;
                color: $text;
                font-weight: 500;
            }
        }
        .passwordIndicator {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin: 20px 0px 10px 0px;

            .passwordIndicatorSingle {
                display: flex;
                font-size: 12px;
                font-weight: 400;
                color: $text;
                font-weight: 500;
                gap: 10px;
                align-items: center;

                img {
                    width: 8px;
                }
                span {
                    opacity: 0.6;
                }
            }
        }
        .errorMessage {
            text-align: right;
            font-size: 12px;
            margin-top: 4px;
            color: $red;
            font-weight: 500;
            position: absolute;
            right: 0;
        }
    }
}