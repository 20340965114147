@media only screen and (max-width: 600px) {

    .header {
        padding: 0px 12px !important;
    }

    input {
        font-size: 16px !important;
        padding: 10px 14px !important;
    }

    .loginRegister {
        align-items: start;
    }
    .loginRegister .container {
        width: 90%;
        margin-top: 110px;
    }
    .loginWithSocial {
        margin-bottom: 100px !important;
    }
    .loginRegisterWithSocial iframe {
        width: 100% !important;
    }
    .loginRegister .container .title {
        font-size: 24px;
    }
    .loginRegister .container .desc {
        font-size: 13px;
    }
    .loginRegister .container .form .inputSingle .inputName span {
        font-size: 17px;
    }
    .loginRegister .container .form .inputSingle .errorMessage {
        font-size: 14px;
    }
    .loginRegister .container .form .inputSingle .inputName .inputForgotPass {
        font-size: 15px;
    }
    .dashboardStats .statsContent .statsContentSingle .iconBox {
        width: 30px;
        height: 30px;
    }
    .dashboardStats .statsContent .statsContentSingle .iconBox svg {
        height: 18px;
        width: 18px;
    }
    .dashboardStats .statsContent .statsContentSingle .num .number {
        font-size: 20px;
    }
    .dashboardStats .statsContent .statsContentSingle .num .desc {
        font-size: 12px;
        font-weight: 500;
    }
    .dashboardStats .top .title {
        font-size: 16px;
    }
    .linkManager .top .title {
        font-size: 16px;
    }
    .writeBio .top .title {
        font-size: 16px;
    }
    .profileSelect .top .title {
        font-size: 16px;
    }
    .colorPicker .top .title {
        font-size: 16px;
    }
    .dashboardStats .top .moreStats {
        font-size: 13px;
    }
    .linkManager .links .linkSingle .linkTitle {
        font-size: 16px;
    }
    .linkManager .links .linkSingle .linkDrag svg {
        width: 20px;
        height: 20px;
    }
    .linkManager .links .linkSingle .linkLeft {
        gap: 8px;
    }
    .linkManager .links .linkSingle .linkURL {
        font-size: 12px;
    }
    .linkManager .links .linkSingle {
        padding: 20px 20px 20px 14px;
    }
    .linkManager {
        padding: 20px;
    }
    .dashboardStats {
        padding: 20px 20px 28px 20px;
    }
    .linkManager .links .linkSingle .linkRight svg {
        width: 20px;
        height: 20px;
    }
    textarea {
        font-size: 16px !important;
    }
    .linkManager .links .addLinkButton {
        font-size: 15px;
    }
    .linkManager .links .addLinkButton svg {
        width: 20px;
        height: 20px;
    }
    .colorPicker .content {
        margin: 20px 0px;
    }
    .colorPicker .content .colorSection {
        text-align: center;
        width: 100%;
    }
    .colorPicker .content .colorPick {
        gap: 24px;
    }
    .dashboardStats .statsContent .statsContentSingle {
        gap: 6px;
    }
    .dashboardContainer {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }
    .dashboard .left .flex {
        gap: 16px;
    }
    .changeUsername .top .secondary {
        display: none;
    }
    .block .mobileButton {
        display: block;
    }
    .changePassword .top .secondary {
        display: none;
    }
    .personalInformation .top .secondary { 
        display: none;
    }
    .chrome-picker {
        width: 100% !important;
        input {
            font-size: 16px !important;
        }
    }
    
}