.profileLink {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    border-radius: 16px;
    padding: 12px 16px;
    font-family: $rubik;
    font-weight: 500;
    color: $text;

    .text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .copyIcon {
        cursor: pointer;
        margin-bottom: -4px;
        img {
            width: 24px;
        }
    }
}