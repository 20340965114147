@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&family=Rubik:wght@400;500;600;700;800&display=swap');

$main: #4945FF;
$mainHover: #3330B3;
$secondary: #121180;
$tertiary: #AC56F5;
$tertiaryHover: #783CAC;
$text: #344B80;
$textSecondary: #1D1B84;
$textTertiary: #A6BAC5;
$background: #EBF4FD;
$bodyBackground: #EAEDF2;
$white: #FFFFFF;
$inputColor: #D8DEEB;
$red: #B00020;
$success: #4BB543;
$fail: #B00020;

// Dark mode colors
$bodyBackgroundDark: #111111;
$backgroundDark: #212121;
$backgroundSecondaryDark: #303030;

$roboto: 'Roboto', sans-serif;
$rubik: 'Rubik', sans-serif;

body {
    margin: 0;
    font-family: $roboto;
    background-color: $bodyBackground;
    overflow-x: hidden !important;
}

.dashboardContainer {
    width: calc(100% - env(scrollbar-width)) !important;
}

a {
    color: inherit !important;
    text-decoration: none !important;
}

.clickable {
    cursor: pointer !important;
}

ol {
    padding-inline-start: 0px !important;
}

li {
    list-style: none;
}

.chrome-picker {
    border-radius: 8px !important;
    box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.1) !important;
    font-family: $rubik !important;

    input {
        height: auto !important;
        padding: 6px 0px;
        font-size: 14px !important;
        border-radius: 8px !important;
        box-sizing: border-box !important;
    }
    input:focus {
        outline: none !important;
    }
}

.tooltipTrigger {
    position: relative;
    .tooltip {
        opacity: 0;
        font-size: 12px;
        background-color: $bodyBackgroundDark;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 6px 10px;
        opacity: 0;
        transition: opacity 0.3s;
        position: absolute;
        z-index: 1;
        width: auto;
        white-space: nowrap;
    }
    .tooltipRight {
        top: -5px;
        left: 120%;
    }
    .tooltipLeft {
        top: -5px;
        right: 120%;
    }
    .tooltipTop {
        bottom:120%;
        left:0%;
    }
    .tooltipBottom {
        top:120%;
        left:0%;
    }
}

.tooltipTrigger:hover {
    .tooltip {
        opacity: 1;
    }
}


.notFound {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;

    .theNum {
        font-size: 60px;
        color: $main;
        font-family: $rubik;
        font-weight: 700;
    }
    .desc {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 14px;
    }
}


.block {
    background-color: $white;
    border-radius: 16px;
    padding: 24px 24px 32px 24px;
    box-sizing: border-box;

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-family: $rubik;
            color: $text;
            font-weight: 600;
            font-size: 18px;
        }
        .secondary {
            color: $tertiary;
            font-size: 15px;
            font-weight: 500;
        }
    }

    .mobileButton {
        display: none;
        text-align: right;
        margin-top: 30px;
    }

    .form {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .formSingle {
        position: relative;
        
        .formTitle {
            margin: 10px 0px;
            font-size: 14px;
            color: $text;
            font-weight: 500;
        }
        .errorMessage {
            text-align: right;
            font-size: 12px;
            margin-top: 4px;
            color: $red;
            font-weight: 500;
            position: absolute;
            right: 0;
        }
    }

    .inputDesc {
        font-size: 12px;
        font-weight: 500;
        margin-top: 8px;
        color: $text;
        opacity: 0.7;
    }
}


.verifyEmailMessage {
    background-color: white;
    border-radius: 16px;
    font-size: 14px;

    .colorContain {
        background-color: rgba(255, 165, 0, 0.1);
        padding: 16px;
        border-radius: 16px;
        color: rgba(255, 165, 0);
    }
}

.react-datepicker-wrapper {
    width: 100% !important;
}
.react-datepicker__input-container {
    input {
        width: 100% !important;
    }
}
.react-datepicker {
    font-family: $rubik !important;
    border: none !important;
    box-shadow: 0px 0px 8px 2px rgba(0,0,0,0.1);
}
.react-datepicker__header {
    border-bottom: none !important;
}
.react-datepicker__header__dropdown {
    margin: 10px 0px;
}
.react-datepicker__triangle::before {
    border-top-color: white !important;
}
.react-datepicker__month-select {
    padding: 2px 4px;
    border-radius: 8px;
    border: 1px solid #dbdbdb;
}
.react-datepicker__year-select {
    padding: 2px 4px;
    border-radius: 8px;
    border: 1px solid #dbdbdb;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    padding: 6px 0px !important;
}
.react-datepicker__navigation--previous {
    top: 6px !important;
}
.react-datepicker__navigation--next {
    top: 6px !important;
}


.verified {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    

    svg {
        width: 80px;
        height: 80px;
        fill: $success;
    }
    .title {
        font-size: 18px;
    }
    .desc {
        font-size: 16px;
        margin-top: 12px;
        opacity: 0.6;
    }
}


input[type=email], input[type=text], input[type=password], input[type=number] {
    font-size: .875rem;
    line-height: 1.25rem;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px $inputColor solid;
    font-family: $roboto;
    color: $text;
    box-sizing: border-box;
}

input[type=email]:focus, input[type=text]:focus, input[type=password]:focus, input[type=number]:focus {
    outline: 2px $main solid !important;
}
input[type=email]::placeholder, input[type=text]::placeholder, input[type=password]::placeholder, input[type=number]::placeholder {
    color: $inputColor;
    opacity: 1; 
} 
input[type=email]:-ms-input-placeholder, input[type=text]:-ms-input-placeholder, input[type=password]:-ms-input-placeholder, input[type=number]:-ms-input-placeholder { 
    color: $inputColor;
} 
input[type=email]::-ms-input-placeholder, input[type=text]::-ms-input-placeholder, input[type=password]::-ms-input-placeholder, input[type=number]::-ms-input-placeholder {
    color: $inputColor;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}



textarea {
    font-size: .875rem;
    line-height: 1.25rem;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px $inputColor solid;
    font-family: $roboto;
    color: $text;
    box-sizing: border-box;
}

textarea:focus {
    outline: 2px $main solid !important;
}
textarea::placeholder {
    color: $inputColor;
    opacity: 1; 
} 
textarea:-ms-input-placeholder { 
    color: $inputColor;
} 
textarea::-ms-input-placeholder {
    color: $inputColor;
}


select {
    font-size: .875rem;
    line-height: 1.25rem;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px $inputColor solid;
    font-family: $roboto;
    color: $text;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    
}
option {
    padding: 6px;
}


.seperator {
    width: 100%;
    border-bottom: 1px solid $inputColor;
    margin: 40px 0px;
}

.saveReminder {
    position: fixed;
    width: 250px;
    padding: 10px;
    border-radius: 8px;
    color: $white;
    top: 80px;
    left: 50%;
    margin-left: -125px;
    z-index: 980;
    text-align: center;
    font-size: 14px;
    background-color: $main;
    color: $white;
    font-weight: 500;
}

.actionMessage {
    position: fixed;
    width: 250px;
    padding: 10px;
    border-radius: 8px;
    color: $white;
    top: 80px;
    left: 50%;
    margin-left: -125px;
    z-index: 999;
    text-align: center;
    font-size: 14px;
}
.actionMessageFail {
    background-color: $fail;
}
.actionMessageSuccess {
    background-color: $success;
}

.loadingScreen {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: $white;

    img {
        width: 80px;
    }
}


.switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100px;
    height: 50px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
  }
  
  .switch-label .switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 45px;
    height: 45px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
  
  .switch-checkbox:checked + .switch-label .switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
  
  .switch-label:active .switch-button {
    width: 60px;
  }


@import 'dark';
@import 'button', 'login', 'dashboard';
@import 'switch';
@import 'leftMenu', 'preview', 'linkManager', 'dashboardStats', 'profileLink' ,'header', 'profileSelect', 'writeBio', 'colorPicker', 'viewChart', 'linkStats', 'changeName', 'changeUsername', 'changePassword';
@import './devices/laptop', './devices/tablet', './devices/mobile';