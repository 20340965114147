.dashboard {
    .left {
        flex-grow: 1;
        padding-right: 32px;

        .flex {
            display: flex;
            flex-direction: column;
            gap: 32px;
            margin-bottom: 32px;
        }
    }
    .right {
        flex: 0 0 350px;

        .fixed {
            width: 350px;
            position: fixed;
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }

    .mobilePreview {
        display: none;
    }
}

.dashboardContainer {
    display: flex;
    flex-direction: row;
    width: 100vw;
    box-sizing: border-box;
    padding-top: 81px;
    padding-left: 120px;
    padding-right: 32px;
}