.button {
    display: inline-block;
    cursor: pointer;
    background-color: $main;
    color: $white;
    font-weight: 500;
    font-size: 15px;
    padding: 11px 32px 12px;
    border-radius: 8px;
    -webkit-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
    box-sizing: border-box;
    text-align: center;

    svg {
        fill: $white;
        width: 20px;
        height: 20px;
    }

    span {
        display: inline-block;
        height: 20px;
        margin-top: 2px;
    }
}

.button:hover {
    background-color: $mainHover;
    transition: .3s;
    transition-timing-function: ease;
}

.buttonIcon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.buttonIconOnly {
    padding: 11px 26px 12px 32px !important;
    img {
        width: 24px;
    }
}

.buttonIconLeft {
    padding: 11px 32px 12px 26px !important;
}

.buttonIconRight {
    padding: 11px 26px 12px 32px !important;
}

.buttonDisabled {
    cursor: unset !important;
    background-color: $background !important;
    color: $textTertiary !important;
}

.buttonSecondary {
    background-color: $background !important;
    color: $main !important;
    svg {
        fill: $main !important;
    }
}
.buttonPurple {
    background-color: $tertiary !important;
}
.buttonPurple:hover {
    background-color: $tertiaryHover !important;
    transition: .3s !important;
    transition-timing-function: ease !important;
}
