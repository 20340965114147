.preview {
    background-color: $white;
    border-radius: 16px;
    text-align: center;
    padding: 32px 16px;
    box-sizing: border-box;
    height: calc(100vh - 81px - 48px - 32px);
    overflow-y: auto;

    .profilePicture {
        display: inline-block;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .name {
        color: $white;
        font-family: $rubik;
        font-size: 15px;
        margin: 12px 0px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;

        .profileBadge {
          margin-bottom: -4px;
        }

        svg {
          width: 18px;
          height: 18px;
        }

        .adminBadgeIcon {
          width: 15px !important;
        }
    }
    .bio {
        color: $white;
        font-family: $rubik;
        font-size: 13px;
        margin: 12px 0px;
        overflow-wrap: break-word;
    }
    .socialIcon {
        margin: 20px 0px;
        svg {
            width: 28px;
            height: 28px;
            margin-right: 5px;
        }
    }
    .links {
        display: flex;
        flex-direction: column;
        margin: 30px 0px;
        gap: 16px;

        .singleLink {
            border-radius: 40px;
            font-size: 16px;
            padding: 14px 12px;
            font-family: $rubik;
            font-weight: 500;
            overflow-wrap: break-word;
        }
    }
}
.preview::-webkit-scrollbar {
  width: 8px;
}
.preview::-webkit-scrollbar-track {
  background: $background; 
}
.preview::-webkit-scrollbar-thumb {
  background: $tertiary; 
}
.preview::-webkit-scrollbar-thumb:hover {
  background: $tertiaryHover; 
}
