.linkStats {
    .content {
        margin: 40px 0px;

        .linksContent {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .linksContentSingle {
                background-color: $background;
                color: $text;
                border-radius: 12px;
                padding: 24px;

                .linksContentTitle {
                    max-width: 100%;
                    font-size: 18px;
                    font-weight: 500;
                    overflow-wrap: break-word;
                    overflow: hidden;
                    word-break: break-all;
                }
                .linksContentURL {
                    font-size: 14px;
                    font-weight: 500;
                    opacity: 0.6;
                    margin-top: 6px;
                    overflow-wrap: break-word;
                    overflow: hidden;
                    word-break: break-all;
                }
                .linksContentStats {
                    display: flex;
                    gap: 16px;
                    margin-top: 14px;

                    .linkContentStatsSingle {
                        display: flex;
                        align-items: center;
                        gap: 6px;
                        font-size: 16px;
                        font-weight: 500;

                        .linkIconBox {
                            display: flex;
                            width: 26px;
                            height: 26px;
                            border-radius: 50%;
                            justify-content: center;
                            align-items: center;
                            svg {
                                width: 18px;
                                height: 18px;
                            }
                        }
                        .clicksIconBox {
                            background-color: #07AF181A;
                            svg {
                                fill: #07AF18;
                            }
                        }
                        .percentIconBox {
                            background-color: #FFA5001A;
                            svg {
                                width: 16px;
                                height: 16px;
                                fill: #FFA500;
                            }
                        }
                    }
                }
            }
        }
    }
}